import mapMissionToMedia from "@shared/helpers/mapMissionToMedia"

export default {
  computed: {
    $_MediaMixin_value() {
      return this.$store.getters.gameStatus?.media
    }
  },
  methods: {
    $_MediaMixin_toggle() {
      const mission = this.$store.getters.getCurrentMission
      const media = mapMissionToMedia(mission)
      return this.$store.dispatch("updateGameMedia", {
        media: this.$_MediaMixin_value === media ? null : media
      })
    }
  }
}
