var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      class: _vm.missionClass,
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "justify-center": "", "fill-height": "" } },
        [
          _vm._t("default"),
          _vm.hasHints
            ? _c(
                "div",
                {
                  staticClass: "hints",
                  on: {
                    click: function ($event) {
                      return _vm.revealHint()
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.missionHintsArray.length === 0
                          ? "Use Hint"
                          : "Review Hints"
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.isMediaBtnVisible
        ? _c(
            "v-tooltip",
            {
              key: "photo",
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      return [
                        _c(
                          "RtbButton",
                          _vm._g(
                            {
                              staticClass: "mission__media-btn",
                              attrs: {
                                size: "sm",
                                outline: "",
                                variant: "icon",
                                active: Boolean(_vm.$_MediaMixin_value),
                              },
                              on: { click: _vm.$_MediaMixin_toggle },
                            },
                            on
                          ),
                          [_c("svg-icon", { attrs: { name: "picture" } })],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1731733912
              ),
            },
            [_c("span", [_vm._v("Show/Hide Mission Photo")])]
          )
        : _vm._e(),
      _vm.hasHints
        ? _c("HintPopup", {
            model: {
              value: _vm.hintPopupOpen,
              callback: function ($$v) {
                _vm.hintPopupOpen = $$v
              },
              expression: "hintPopupOpen",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }