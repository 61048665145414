<template>
  <v-layout column justify-center fill-height :class="missionClass">
    <v-layout column justify-center fill-height>
      <slot />
      <div @click="revealHint()" class="hints" v-if="hasHints">
        {{ missionHintsArray.length === 0 ? "Use Hint" : "Review Hints" }}
      </div>
    </v-layout>
    <v-tooltip v-if="isMediaBtnVisible" key="photo" bottom>
      <template v-slot:activator="{ on }">
        <RtbButton
          v-on="on"
          size="sm"
          outline
          variant="icon"
          class="mission__media-btn"
          :active="Boolean($_MediaMixin_value)"
          @click="$_MediaMixin_toggle"
        >
          <svg-icon name="picture" />
        </RtbButton>
      </template>
      <span>Show/Hide Mission Photo</span>
    </v-tooltip>
    <HintPopup v-if="hasHints" v-model="hintPopupOpen" />
  </v-layout>
</template>

<script>
import Mission from "@shared/enums/Mission"
import { mapGetters } from "vuex"
import MediaMixin from "@/mixins/MediaMixin"
import { RtbButton } from "@/components/ui"

export default {
  name: "Mission",
  components: {
    HintPopup: () => import("@/components/Popups/HintPopup"),
    RtbButton
  },
  mixins: [MediaMixin],
  props: {
    mode: String,
    mission: Object
  },
  data() {
    return {
      hintPopupOpen: false
    }
  },
  watch: {
    // is it even working?
    missionHintsArrayByTeam(value) {
      if (value.length === 1) this.hintPopupOpen = true
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost", "isModerator"]),
    ...mapGetters(["missionHintsArray", "teamID"]),
    isMediaBtnVisible() {
      const mission = this.mission || {}
      return (
        (this.isHost || this.isModerator) &&
        mission.behavior !== Mission.Info &&
        mission.behavior !== Mission.Lipdub &&
        mission.behavior !== Mission.HandMovement &&
        (mission.photo || mission.video || mission.audio || mission.custom)
      )
    },
    title() {
      return this.mission?.title
    },
    points() {
      return this.mission?.points
    },
    missionClass() {
      const behavior = this.mission?.behavior ?? undefined
      return {
        mission: behavior !== Mission.YouTube,
        "mission-youtube": behavior === Mission.YouTube,
        "mission--giphy": behavior === Mission.Giphy,
        "mission--categories": behavior === Mission.Categories
      }
    },
    missionHintsArrayByTeam() {
      const currentMissionID = this.mission?.id
      const teamID = this.teamID
      return this.missionHintsArray.filter(
        hint => hint.teamID === teamID && hint.missionID === currentMissionID
      )
    },
    hasHints() {
      return this.mission?.hints ? this.mission.hints[0] : null
    },
    behavior() {
      return this.$store.getters.getCurrentMission?.behavior
    },
    missionID() {
      return this.mission.id
    }
  },
  methods: {
    revealHint() {
      this.hintPopupOpen = true
    }
  }
}
</script>

<style lang="scss" scoped>
.mission {
  position: relative;
  background: radial-gradient(
    137.87% 137.87% at 49.24% 9.52%,
    #2c2c2c 0%,
    #000000 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 20px;
  color: #fff;
  padding: 15px 35px 20px 35px;
  font-family: Sofia Pro;
  font-weight: 900 !important;

  &__media-btn {
    position: absolute;
    bottom: 48px;
    right: 10px;
  }
}

.mission-result {
  position: relative;
  overflow: hidden;
  padding: 5px;
  box-sizing: border-box;
  color: #fff;
  padding: 5px 10px 15px 10px;
  margin: 0 14px 0 14px;
  font-family: Sofia Pro;
  font-weight: 900 !important;
}

/* .mission-youtube {
  @extend .rtb-border, .rtb-border-radius;
  position: relative;
  overflow: hidden;
  padding: 5px;
} */

.mission.hide-out {
  animation-name: hide-out;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-iteration-count: forwards;
  animation-fill-mode: both;
}

@keyframes hide-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: scale(0.6);
    opacity: 0;
  }
}

.mission-type {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -8px;
  margin-top: -8px;
  color: $color-white;
  font-size: 1.2rem;
}

.the-hint {
  padding: 0 5px;
  cursor: pointer;
  &:hover {
    filter: brightness(1.1);
  }
}

.hints {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  padding: 8px 24px;
  border-radius: 19px 0;
  background-color: #292932;
  color: #b6d6ff;
}

.mission-type-bg {
  @extend .rtb-border-radius;
  background-color: $color-primary-dark;
  padding-top: 13px;
  padding-bottom: 6px;
  padding-right: 20px;
  padding-left: 14px;
}

.mission-type-text {
  font-size: 13px;
  line-height: 14px;
  height: 14px;
  font-style: italic;
}

.mission-pts-text {
  font-size: 12px;
  line-height: 14px;
  height: 14px;
}

.mission-score {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: -8px;
  margin-bottom: -8px;
  color: $secondary_accent_color;
  font-size: 1.2rem;
}

.mission-score-number {
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  height: 20px;
}

.mission-score-bg {
  @extend .rtb-border-radius;
  color: $color-white;
  background-color: $color-primary-dark;
  padding-bottom: 14px;
  padding-top: 8px;
  padding-left: 18px;
  padding-right: 12px;
}
</style>
